import React,{ Component } from 'react';
import './Home.css';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';
import  Vertical_1 from '../../_components/Vertical_1';
import  Horizontal_1 from '../../_components/Horizontal_1';
import  Slider_1 from '../../_components/Slider_1';
import  Ticker_1 from '../../_components/Ticker_1';

import { DateHelper } from '../../_helpers/DateHelper';
import Graphtopissue from '../Quantitative/Graphtopissue';

import  Graphtonemedia from '../Quantitative/Graphtonemedia';
import Graphtonenarasumber from '../Quantitative/Graphtonenarasumber';

// image //
import Image1 from '../../_assets/img/bg-img/16.jpg';
import TwitterContainer from '../../_components/TwitterContainer';
import Maps from '../../_components/Maps';


export default class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            
            dateFrom : '2019-01-01',
            dateTo : DateHelper(),
            client : JSON.parse(localStorage.getItem('_profile'))
        }
        
    }

    componentDidMount() {      
        // console.log(datasets[0].data);
      }
    
     
    render(){        

        return (

                <div>
                    <Header></Header>
                    
                    <div className="hero-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-12">
                                    <Ticker_1 total="5"></Ticker_1>
                                </div>

                            </div>
                        </div>
                    </div>                            

                            <div className="featured-post-area">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-5">
                                            <div className='bdz-header-title'  > 
                                                Berita Utama
                                            </div>
											
                                            <Slider_1 total="10"></Slider_1>    

                                        </div>

                                        <div className="col-12 col-md-7 col-lg-7">
                                            <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                    
                                                <div className="col-12 col-lg-12">
                                                    <div className='bdz-header-title'  > 
                                                        Berita Cetak
                                                    </div>
                                                    <Vertical_1 total="3" showBig1="true" type="printed" category=""></Vertical_1>
                                                </div>
                                            </div>
											<div className="col-12 col-md-6 col-lg-6">
                                                <div className='col-12 col-md-12 col-lg-12'>
                                                    <div className='bdz-header-title' > 
                                                        Berita Online
                                                    </div>
                                                    <Vertical_1 total="3" showBig1="true" type="online" category=""></Vertical_1>                                                    
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

							<Horizontal_1 total="4" type="onlineprinted" categoryID="1883" category="QNB"></Horizontal_1>
							<Horizontal_1 total="4" type="onlineprinted" categoryID="1882" category="Kompetitor"></Horizontal_1>

                            <div className="container">
                                <br></br>
                                <hr></hr>
                                <div className='row'>
                                    <div className="col-12 col-md-4 col-lg-4">
                                            
                                        <div className="col-12 col-lg-12">
                                            <Graphtopissue title="Grafik Top Isu" height={250} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className='col-12 col-md-12 col-lg-12'>
                                            <Graphtonemedia title="Grafik Tone Media" widt="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneMedia} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 col-lg-4">
                                        <div className='col-12 col-md-12 col-lg-12'>
                                             <Graphtonenarasumber title="Grafik Tone Narasumber" width="300" height="200" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneNarasumber}/>
                                        </div>
                                    </div>



                                </div>
                            </div>


                          


                            {/* TV & RADIO NEWS */}

                            
                            <div className="video-post-area bg-img bg-overlay" style={{backgroundImage:"url("+Image1+")"}}>
                                
                                <div className="container">
                                    <Horizontal_1 total="4" type="tv" category="Berita TV" titleColor="#fff"></Horizontal_1>  
									{/* <Horizontal_1 total="4" type="radio" category="Berita Radio" titleColor="#fff"></Horizontal_1> */}
                                    
                                </div>
                            </div>
							
							
                            {/* BY TOPIC */}

							
                            {/* <Horizontal_1 total="4" type="printedonline" categoryID="1879" category="Presiden"></Horizontal_1> */}
							
							
                        <div className='container'>
                            <div className="row">
                                <div className='col-6 bdz-medsos-item'>
                                        
						            {/* <a class="twitter-timeline" href="https://twitter.com/BNPB_Indonesia" data-widget-id="690321167098376192">Tweets BNPB</a> */}
                                    {/* <a className="twitter-timeline" data-height="400" href="https://twitter.com/BKKBNofficial?ref_src=twsrc%5Etfw">Tweets by BKKBNofficial</a>  */}
                                    {/* <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
            
                                </div>
                                <div className='col-6 bdz-medsos-item'>
                                    {/* 
									<div id="fb-root"></div>
                                    
                                    <div class="fb-page" data-href="https://www.facebook.com/InfoBencanaBNPB/" data-tabs="timeline" 
                                        data-height="400" 
                                        data-small-header="false" 
                                        data-adapt-container-width="true" 
                                        data-hide-cover="true" 
                                        data-show-facepile="true">
                                        <blockquote cite="https://www.facebook.com/InfoBencanaBNPB/" class="fb-xfbml-parse-ignore">
                                            <a href="https://www.facebook.com/InfoBencanaBNPB/">BNPB</a>
                                        </blockquote>
                                    </div>
									*/}
                                </div>
                            </div>
                        </div>
                    <Footer></Footer>
                            
                </div>
            
        
    
        ) 
    }
  
}

